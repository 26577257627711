import React from 'react';
import Image from 'gatsby-image'
import styled from 'styled-components'
import TextStructured from './textStructured'
import BtnCotizador from './utils/btnCotizador';

const SectionStyle = styled.section`
    padding: 1em 0; 
    display: grid;
    align-items: center;
    @media (min-width: 1024px) {
        max-width: 1620px;
        margin: 0 auto;
        padding: 2em 4em 3em 4em;
        grid-template-columns: 60% 40%;
    }

`

const DataStyle = styled.div`
    padding: 2em 1em;
    background-color: #f6f6f6;
    @media (min-width: 1024px) {
        padding: 3em 3em;
    }
    
`;

const ImgStyle = styled(Image)`
    border-radius: 4px;
    transition-duration: 1s;
`;


const HeroServicio = ({cover, titulo, introduccion, servicio}) => {
    return ( 
        <>
            <SectionStyle>
                <ImgStyle fluid={cover.fluid} alt={titulo}/>
                <DataStyle>
                    <TextStructured content={titulo} />   
                    <br></br>
                    <TextStructured content={introduccion} />  
                    <br></br>
                    <BtnCotizador servicio={servicio}/>
                </DataStyle>            
            </SectionStyle>
        </> 
        
     );
}
 
export default HeroServicio;
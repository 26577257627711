import React from 'react';
import Image from 'gatsby-image'
import styled from 'styled-components'
import TextStructured from './textStructured'

const SectionStyle = styled.section`
    padding: 3em 1em 1em 1em; 
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(2, 1fr); 
    @media (min-width: 1024px) {
        max-width: 80%;
        margin: 0 auto;
        padding: 6em 2em 2em 2em;
        grid-template-columns: repeat(3, 1fr); 
        gap: 3em;
    }
`
const ConceptoStyle = styled.div`       
   p{
       font-size: 1em;
   }
   @media (min-width: 1024px) {
        border-bottom: 2px solid #ffffff;
        &:hover{        
            border-bottom: 2px solid #77b843;
        }
    }
    
`;

const ImgStyle = styled(Image)`
    width: 60px; 
    width: 60px; 
    margin-bottom: 1em;
`;

const ConceptosServicios = ({img1, titulo1, desc1, img2, titulo2, desc2, img3, titulo3, desc3,img4, titulo4, desc4, img5, titulo5, desc5, img6, titulo6, desc6, img7, titulo7, desc7 }) => {
    return ( 
        <SectionStyle>            
            <ConceptoStyle>
                <ImgStyle fluid={img1.fluid} alt={titulo1}/>
                <TextStructured content={titulo1} />   
                <TextStructured content={desc1} />   
            </ConceptoStyle>
            <ConceptoStyle>
                <ImgStyle fluid={img2.fluid} alt={titulo2}/>
                <TextStructured content={titulo2} />   
                <TextStructured content={desc2} />   
            </ConceptoStyle>
            <ConceptoStyle>
                <ImgStyle fluid={img3.fluid} alt={titulo3}/>
                <TextStructured content={titulo3} />   
                <TextStructured content={desc3} />   
            </ConceptoStyle>
            { img4 ?
                <ConceptoStyle>
                    <ImgStyle fluid={img4.fluid} alt={titulo4}/> 
                    <TextStructured content={titulo4} />   
                    <TextStructured content={desc4} />   
                </ConceptoStyle>
            : null}  
            { img5 ?
                <ConceptoStyle>
                    <ImgStyle fluid={img5.fluid} alt={titulo5}/> 
                    <TextStructured content={titulo5} />   
                    <TextStructured content={desc5} />   
                </ConceptoStyle>
            : null}  
            { img6 ?
                <ConceptoStyle>
                    <ImgStyle fluid={img6.fluid} alt={titulo6}/> 
                    <TextStructured content={titulo6} />   
                    <TextStructured content={desc6} />   
                </ConceptoStyle>
            : null}  
            { img7 ?
                <ConceptoStyle>
                    <ImgStyle fluid={img7.fluid} alt={titulo7}/> 
                    <TextStructured content={titulo7} />   
                    <TextStructured content={desc7} />   
                </ConceptoStyle>
            : null}                                                                                                           
        </SectionStyle>
     );
}
 
export default ConceptosServicios;
import React from 'react';
import styled from 'styled-components'
import Image from 'gatsby-image'
import TextStructured from './textStructured'

const SectionStyle = styled.section`
    padding: 3em 1em 1em 1em; 
    @media (min-width: 1024px) {
        max-width: 1620px;
        margin: 0 auto;
        padding: 3em 3em 3em 3em;
    }
    h2{
        font-size: 1em;
        @media (min-width: 1024px) {
            font-size: 1.8em;
        }
    }
`


const GridStyle = styled.div`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    gap: 5px;
    div{
        padding: 8px 6px;
        background-color: #f6f6f6;
    }
    @media (min-width: 1024px) {
        margin: 0 auto;
        padding: 2em 0 0 0;
        grid-template-columns: repeat(3,1fr);
        gap: 3em;
        div{
            padding: 1em;
        }
    }
`;

const ImgStyle = styled(Image)`
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
`;

const GaleriaServicio = ({titulogaleria, galeria}) => {
    return (
        <SectionStyle>
            <GridStyle>
                <div>
                    <h2><TextStructured content={titulogaleria} /> </h2>
                </div>
                {galeria.map(data => (
                    <ImgStyle fluid={data.fluid} alt="Cliente Inteligencia Solar" />                
                ))}  
            </GridStyle>              
        </SectionStyle>
      );
} 

export default GaleriaServicio;
import React from 'react';
import Video from './utils/video'
import styled from 'styled-components'

const SectionStyle = styled.section`
    padding: 2em 1em; 
    h2{
        text-align: center;
    }
    @media (min-width: 1024px) {
        max-width: 1620px;
        margin: 0 auto;
        padding: 6em 4em 2em 4em;
    }
`; 

const GridStyle = styled.div`
    padding: 2em 0 1em 0; 
    display: grid;
    gap: 1em;
    @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2em;
    }
`;

const ItemStyle = styled.div`
    background-color: #f6f6f6;
    border-radius: 5px;
    padding: 1em;
    @media (min-width: 1024px) {
        width: 600px;
        height: 320px;
    }
`;

const Multimedia = ({ video1, video2, video3 }) => {
    return ( 
        <>
        {
            video1 || video2 || video3 ? 
            <SectionStyle>
                <h2>Multimedia</h2>
                <GridStyle>
                    {
                        video1 ? 
                        <ItemStyle>
                            <Video urlvideo={video1.url} />
                        </ItemStyle>
                        : null
                    }
                    {
                        video2 ? 
                        <ItemStyle>
                            <Video urlvideo={video2.url} />
                        </ItemStyle>
                        : null
                    }
                    {
                        video3 ? 
                        <ItemStyle>
                            <Video urlvideo={video3.url} />
                        </ItemStyle>
                        : null
                    }                                        
 
                </GridStyle>

            </SectionStyle>
            : null
        }
        </>
   
     );
}
 
export default Multimedia;
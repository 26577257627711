import React from 'react';
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const SectionStyle = styled.section`
    padding: 2em 1em 4em 1em; 
    @media (min-width: 1024px) {
        max-width: 80%;
        margin: 0 auto;
        padding: 6em 2em 9em 2em;
    }
    h2{
        font-size: 1.5em;
        @media (min-width: 1024px) {
            font-size: 2em;
        }
    }
`

const GridStyle = styled.div`
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr); 
    @media (min-width: 1024px) {
        margin: 0 auto;
        padding: 2em 0 0 0;
        grid-template-columns: repeat(4, 1fr); 
        gap: 1em;
        row-gap: 2em;
    }
`;

const PdfStyle = styled.div`
    display: grid;
    gap: 2px;
    text-align: center;
    @media (min-width: 1024px) {
        text-align: left;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    @media (max-width: 1024px) {
        a{
            font-size: 10px;
        }
    }
`;

const CatalogoServicio = ({ catalogos }) => {
    return (
        <SectionStyle>
            <h2>Consulta nuestros catálogos</h2>
            <br></br>
            <GridStyle>
                {catalogos.map(data => (
                    <PdfStyle>
                        <a href={data.url} target='blank' rel="noreferrer">
                        <StaticImage
                            src="../images/pdf.png"
                            width={40}
                            quality={100}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Menú"
                            style={{ marginLeft: '10px'}}
                        />      
                        </a>          
                        <a href={data.url} target='blank' rel="noreferrer">{data.title}</a>  
                    </PdfStyle>
                ))} 
            </GridStyle>
        </SectionStyle>
      );
} 

export default CatalogoServicio;